.swiper {
  width: 100%;
  height: 100%;
}
  
  .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullets {
  margin-right: 3rem;
}
.swiper-pagination-bullet {
  height: 0.8rem;
  width: 0.8rem;
  background-color: white;
  
}

@media (max-width: 768px) {
  .swiper-pagination-bullets {
    margin-right: 1rem;
  }
  .swiper-pagination-bullet {
    height: 0.5rem;
    width: 0.5rem;
  }
}